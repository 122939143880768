<template>
  <div class="d-flex flex-wrap">
    <!-- <v-col cols="12" md="4">
      <v-card class="card" style="height: auto; border-radius: 1rem">
        <v-col class="pa-4">
          <v-col class="pa-1 ma-0">
            <v-card-title class="pa-0">100</v-card-title>
            <v-card-text class="pa-0">Online</v-card-text>
          </v-col>

          <vue-apex-charts :options="" :series="" height="100"></vue-apex-charts>
        </v-col>
      </v-card>
    </v-col> -->

    <v-col cols="12" md="4">
      <v-card class="card" style="height: 100%; border-radius: 1rem">
        <v-card-title class="px-4"> {{ $t('dashboard.bank') }} </v-card-title>
        <v-card-text class="pa-0 pb-8">
          <v-data-table
            mobile-breakpoint="0"
            :headers="headersAccountBanks"
            :items="accountBanks"
            :no-data-text="$t('not_found')"
            :no-results-text="$t('not_found')"
            disable-pagination
            hide-default-footer
          >
            <template v-slot:[`item.bank`]="{ item }">
              <v-row class="mx-0 align-center" style="height: auto;gap:0.5rem;flex-wrap: nowrap;">
                <div style="width: 24px;height: 24px;aspect-ratio: 1;display: grid;">
                  <div v-if="!item.bankCountry">
                    <img v-if="item.bank" alt="bank_img"
                      style="object-fit: contain;height: inherit;width: 100%;"
                      :src="require(`@/assets/images/logos/banks/${item.bank}.png`)" />
                  </div>
                  <div v-else>
                    <img v-if="item.bank" alt="bank_img"
                      style="object-fit: contain;height: inherit;width: 100%;"
                      :src="`https://www.ak47.services/akasset-v2/banks/${item.bankCountry}/${item.bank}.webp`" />
                  </div>
                </div>

                <v-col class="pa-0" style="line-height: 1.25">
                  <v-row no-gutters>
                    <span class="text-left" style="font-size: 1rem; font-weight: 500">{{ item.bank_account }}</span>
                  </v-row>
                  <v-row no-gutters>
                    <span class="text-left" style="font-size: 0.8rem; font-weight: 300">{{ item.bank_name }}</span>
                  </v-row>
                </v-col>
              </v-row>
            </template>
            <template v-slot:[`item.type`]="{ item }">
              <div class="d-flex flex-row align-center justify-center" style="gap: 0.25rem;">
                <span
                  v-if="item.deposit"
                  class="px-2 py-1"
                  style="
                    border-radius: 0.5rem;
                    color: #fff;
                    font-weight: 500;
                    background: linear-gradient(to bottom, #0d9, #0d9);
                    box-shadow: 0px 0px 10px 2px rgba(0, 204, 136, 0.25);
                  "
                  >
                  {{ $t('dashboard.deposit') }}
                </span>
                <span
                  v-if="item.withdraw"
                  class="px-2 py-1"
                  style="
                    border-radius: 0.5rem;
                    color: #fff;
                    font-weight: 500;
                    background: linear-gradient(to bottom, #f74b6c, #e63f59);
                    box-shadow: 0px 0px 10px 2px rgba(230, 63, 89, 0.25);
                  "
                >
                  {{ $t('dashboard.withdraw') }}
                </span>
              </div>
            </template>
            <template v-slot:[`item.balance`]="{ item }">
              <span style="font-size: 1rem; font-weight: 500">{{ item.balance | currency }}</span>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="12" md="8">
      <v-card class="card" style="height: auto; border-radius: 1rem">
        <v-row class="align-center justify-space-between ma-0">
          <v-card-title>
            <span>{{ $t('dashboard.summary_player_head') }}</span>
          </v-card-title>
          <v-card-title style="font-size: 0.75rem; font-weight: 300">
            <span>{{ $t('dashboard.summary_updated_at') }}: {{ currentDate }}</span>
          </v-card-title>
        </v-row>
        <v-spacer></v-spacer>

        <!-- member summary  -->
        <v-row class="ma-0">
          <v-col cols="12" sm="6" lg="3">
            <v-card-actions class="d-md-flex flex-md-column flex-lg-row">
              <v-avatar color="#28c76f20">
                <v-icon size="30px" color="#28c76f"> mdi-account-multiple-outline </v-icon>
              </v-avatar>
              <div
                class="d-md-flex flex-md-column justify-center align-md-center align-lg-start pl-4 pl-md-0 pl-lg-4 pt-0 pt-md-2 pt-lg-0"
              >
                <v-card-title
                  class="justify-start text-h6 px-0 py-1"
                  style="font-size: 1rem !important; font-weight: 700; color: #28c76f !important"
                >
                  {{ report.total_member_register }}
                </v-card-title>
                <v-card-text class="text-left pa-0" style="font-size: 0.75rem">
                  {{ $t('dashboard.summary_new_player') }}
                </v-card-text>
              </div>
            </v-card-actions>
          </v-col>

          <v-col cols="12" sm="6" lg="3">
            <v-card-actions class="d-md-flex flex-md-column flex-lg-row">
              <v-avatar color="#00cfe820">
                <v-icon size="30px" color="#00cfe8"> mdi-account-multiple-outline </v-icon>
              </v-avatar>
              <div
                class="d-md-flex flex-md-column justify-center align-md-center align-lg-start pl-4 pl-md-0 pl-lg-4 pt-0 pt-md-2 pt-lg-0"
              >
                <v-card-title
                  class="justify-start text-h6 px-0 py-1"
                  style="font-size: 1rem !important; font-weight: 700"
                >
                  {{ members.all_member.toLocaleString('en-US') }}
                </v-card-title>
                <v-card-text class="text-left pa-0" style="font-size: 0.75rem">
                  {{ $t('dashboard.summary_all_player') }}
                </v-card-text>
              </div>
            </v-card-actions>
          </v-col>

          <v-col cols="12" sm="6" lg="3">
            <v-card-actions class="d-md-flex flex-md-column flex-lg-row">
              <v-avatar color="#f74b6c20">
                <v-icon size="30px" color="#f74b6c"> mdi-account-multiple-outline </v-icon>
              </v-avatar>
              <div
                class="d-md-flex flex-md-column justify-center align-md-center align-lg-start pl-4 pl-md-0 pl-lg-4 pt-0 pt-md-2 pt-lg-0"
              >
                <v-card-title
                  class="justify-start text-h6 px-0 py-1"
                  style="font-size: 1rem !important; font-weight: 700"
                >
                  {{ members.member_deposit.toLocaleString('en-US') }}
                </v-card-title>
                <v-card-text class="text-left pa-0" style="font-size: 0.75rem">
                  {{ $t('dashboard.summary_player_deposit') }}
                </v-card-text>
              </div>
            </v-card-actions>
          </v-col>

          <v-col cols="12" sm="6" lg="3">
            <v-card-actions class="d-md-flex flex-md-column flex-lg-row">
              <v-avatar color="#ff9f4320">
                <v-icon size="30px" color="#ff9f43"> mdi-account-multiple-outline </v-icon>
              </v-avatar>
              <div
                class="d-md-flex flex-md-column justify-center align-md-center align-lg-start pl-4 pl-md-0 pl-lg-4 pt-0 pt-md-2 pt-lg-0"
              >
                <v-card-title
                  class="justify-start text-h6 px-0 py-1"
                  style="font-size: 1rem !important; font-weight: 700"
                >
                  {{ members.member_not_deposit.toLocaleString('en-US') }}
                </v-card-title>
                <v-card-text class="text-left pa-0" style="font-size: 0.75rem">
                  {{ $t('dashboard.summary_player_not_deposit') }}
                </v-card-text>
              </div>
            </v-card-actions>
          </v-col>
        </v-row>
      </v-card>
    </v-col>

    <v-row cols="12" md="12" class="ma-0 pa-0" style="width: -webkit-fill-available">
      <v-col cols="12" md="4">
        <v-row style="height: 100%">
          <v-col cols="12" md="6">
            <v-card
              class="card pa-4 d-flex flex-column justify-space-between"
              style="height: 100%; border-radius: 1rem"
            >
              <v-col class="pa-0">
                <div class="d-flex flex-column" style="font-size: 0.9rem; line-height: 1.1; font-weight: 500">
                  <span
                    ><v-icon size="16px" color="#28c76f" class="mr-1"> mdi-arrow-up-circle-outline </v-icon
                    >{{ $t('dashboard.blocksmall_today_deposit') }}
                  </span>
                  <span>
                    <span style="font-weight: 500; color: #0d9">{{ report.count_deposit }}</span>
                    {{ $t('dashboard.blocksmall_list') }}
                  </span>
                </div>
                <div>
                  <span style="font-size: 1.25rem !important; font-weight: 700; color: #0d9"
                    >+{{ report.total_amount_deposit | currency }} ฿</span
                  >
                </div>
              </v-col>

              <div v-if="!this.isLoadingChartDepositWithdrawSummary">
                <vue-apex-charts
                  :options="chartOptionsDeposit"
                  :series="chartDepositSeries"
                  height="140"
                ></vue-apex-charts>
              </div>
            </v-card>
          </v-col>

          <v-col cols="12" md="6">
            <v-card
              class="card pa-4 d-flex flex-column justify-space-between"
              style="height: 100%; border-radius: 1rem"
            >
              <v-col class="pa-0">
                <div class="d-flex flex-column" style="font-size: 0.9rem; line-height: 1.1; font-weight: 500">
                  <span
                    ><v-icon size="16px" color="#f74b6c" class="mr-1"> mdi-arrow-down-circle-outline </v-icon
                    >{{ $t('dashboard.blocksmall_today_withdraw') }}
                  </span>
                  <span>
                    <span style="font-weight: 500; color: #f74b6c">{{ report.count_withdraw }}</span>
                    {{ $t('dashboard.blocksmall_list') }}
                  </span>
                </div>
                <div>
                  <span style="font-size: 1.25rem !important; font-weight: 700; color: #f74b6c"
                    >-{{ report.total_amount_withdraw | currency }} ฿</span
                  >
                </div>
              </v-col>

              <div v-if="!this.isLoadingChartDepositWithdrawSummary">
                <vue-apex-charts
                  :options="chartOptionsWithdraw"
                  :series="chartWithdrawSeries"
                  height="140"
                ></vue-apex-charts>
              </div>
            </v-card>
          </v-col>

          <v-col cols="12" md="6">
            <v-card
              class="card pa-4 d-flex flex-column justify-space-between"
              style="height: 100%; border-radius: 1rem"
            >
              <v-col class="pa-0">
                <div class="d-flex flex-column" style="font-size: 0.9rem; line-height: 1.1; font-weight: 500">
                  <span
                    ><v-icon size="16px" color="#00cfe8" class="mr-1"> mdi-arrow-down-circle-outline </v-icon
                    >{{ $t('dashboard.blocksmall_bonus') }}
                  </span>
                </div>
                <div>
                  <span style="font-size: 1.25rem !important; font-weight: 700"
                    >{{ report.total_amount_bonus | currency }}฿</span
                  >
                </div>
              </v-col>

              <div v-if="!this.isLoadingChartDepositWithdrawSummary">
                <vue-apex-charts :options="chartOptionsBonus" :series="chartBonusSeries" height="140"></vue-apex-charts>
              </div>
            </v-card>
          </v-col>

          <v-col cols="12" md="6">
            <v-card
              class="card pa-4 d-flex flex-column justify-space-between"
              style="height: 100%; border-radius: 1rem"
            >
              <v-col class="pa-0">
                <div class="d-flex flex-column" style="font-size: 0.9rem; line-height: 1.1; font-weight: 500">
                  <span
                    ><v-icon size="16px" color="#ff9f43" class="mr-1"> mdi-arrow-down-circle-outline </v-icon
                    >{{ $t('dashboard.blocksmall_game') }}
                  </span>
                </div>
                <div>
                  <span style="font-size: 1.25rem !important; font-weight: 700"
                    >{{ report.total_amount_game | currency }}฿</span
                  >
                </div>
              </v-col>

              <div v-if="!this.isLoadingChartDepositWithdrawSummary">
                <vue-apex-charts :options="chartOptionsGame" :series="chartGameSeries" height="140"></vue-apex-charts>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="8">
        <v-card class="card" style="height: 100%; border-radius: 1rem">
          <div class="dw-summary-container ma-0 d-flex flex-column flex-md-row">
            <v-col cols="12" md="8" class="ma-0 pa-0">
              <v-card-title
                >{{ $t('dashboard.block2_head') }}
                <span
                  class="pl-1"
                  :style="{ fontWeight: 500, color: report.total_amount_profit >= 0 ? '#0d9' : '#f74b6c' }"
                >
                  <span v-if="report.total_amount_profit >= 0">+</span
                  >{{ report.total_amount_profit | currency }} ฿</span
                >
                <v-icon size="18px" :style="{ color: report.total_amount_profit >= 0 ? '#0d9' : '#f74b6c' }">
                  {{ report.total_amount_profit >= 0 ? 'mdi-trending-up' : 'mdi-trending-down' }}
                </v-icon>
              </v-card-title>
              <v-row class="px-4" style="padding-left: 28px !important; justify-content: start">
                <v-col cols="3" class="px-1">
                  <v-btn
                    style="width: 100% !important"
                    color="success"
                    :block="selectedChartType === 'day'"
                    :outlined="selectedChartType !== 'day'"
                    @click="selectedChartType = 'day'"
                    >{{ $t('dashboard.block2_daily') }}</v-btn
                  >
                </v-col>
                <v-col cols="3" class="px-1">
                  <v-btn
                    style="width: 100% !important"
                    color="success"
                    :block="selectedChartType === 'month'"
                    :outlined="selectedChartType !== 'month'"
                    @click="selectedChartType = 'month'"
                    >{{ $t('dashboard.block2_monthly') }}</v-btn
                  >
                </v-col>
              </v-row>
              <div v-if="!this.isLoadingChartDepositWithdrawSummary && selectedChartType === 'day'" class="pa-4 mb-3">
                <vue-apex-charts
                  :options="chartDepositWithdrawSummaryOptions"
                  :series="chartDepositWithdrawSummarySeries"
                  height="340"
                ></vue-apex-charts>
              </div>
              <div
                v-if="!this.isLoadingChartDepositWithdrawSummaryMonth && selectedChartType === 'month'"
                class="pa-4 mb-3"
              >
                <vue-apex-charts
                  :options="chartDepositWithdrawSummaryOptionsMonth"
                  :series="chartDepositWithdrawSummarySeriesMonth"
                  height="340"
                ></vue-apex-charts>
              </div>
            </v-col>
            <v-divider vertical />
            <v-col
              cols="12"
              md="4"
              class="dw-summary-text-container d-flex flex-column align-center justify-center pa-0"
            >
              <div class="mb-4" :style="{ width: '50%', height: '42px' }">
                <v-select
                  v-model="selectedMonth"
                  :items="monthOptions"
                  item-text="text"
                  item-value="value"
                  outlined
                  dense
                  @change="selectedMonthChart"
                ></v-select>
              </div>

              <span>{{ $t('dashboard.block2_net_profit') }}</span>
              <span :style="{ color: 'var(--v-info-base)', fontSize: '1.5em', fontWeight: 700 }"
                >{{ totalProfit | currency }}฿</span
              >
              <span style="color: #0d9; font-size: 0.9rem; font-weight: 700"
                >{{ $t('dashboard.block2_deposit') }}:
                <span style="font-weight: 500">+{{ totalDeposit | currency }}฿</span></span
              >
              <span style="color: #f74b6c; font-size: 0.9rem; font-weight: 700"
                >{{ $t('dashboard.block2_withdraw') }}:
                <span style="font-weight: 500">-{{ totalWithdraw | currency }}฿</span></span
              >
              <span style="color: #00cfe8; font-size: 0.9rem; font-weight: 700"
                >{{ $t('dashboard.block2_bonus') }}:
                <span style="font-weight: 500">{{ totalBonus | currency }}฿</span></span
              >
              <span style="color: #ff9f43; font-size: 0.9rem; font-weight: 700"
                >{{ $t('dashboard.block2_game') }}:
                <span style="font-weight: 500">{{ totalGames | currency }}฿</span></span
              >
            </v-col>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <v-col cols="12" md="8">
      <v-card class="card" style="height: auto; border-radius: 1rem">
        <div class="d-flex flex-sm-row flex-column">
          <div class="flex-grow-1">
            <v-card-title>
              <span class="me-3 error--text">{{ $t('dashboard.block3_head') }}</span>
              <v-spacer></v-spacer>
            </v-card-title>
            <v-divider />
            <v-card-text class="pa-0 pb-8">
              <v-data-table
                mobile-breakpoint="0"
                :headers="headersWithdraw"
                :items="withdrawTransaction"
                :no-data-text="$t('not_found')"
                :no-results-text="$t('not_found')"
                disable-pagination
                hide-default-footer
              >
                <template v-slot:[`item.status`]="{ item }">
                  <div class="d-flex align-center justify-center">
                    <div :class="`${item.status === 'denied' || item.status === 'cancel'
                      ? 'error'
                      : item.status === 'wait'
                        ? 'warning'
                        : 'success'
                      } popout active mr-2`" :style="`box-shadow: ${item.status === 'success'
                        ? '0px 0px 8px 2px #00dd9944'
                        : item.status === 'denied' || item.status === 'cancel'
                          ? '0px 0px 8px 2px #f74b6c44'
                          : '0px 0px 8px 2px #ffb40044'
                      }`">
                    </div>
                    <span>{{
                      item.status === 'denied' || item.status === 'cancel'
                        ? $t('dashboard.block3_not_approved')
                        : item.status === 'wait'
                        ? $t('dashboard.block3_approve_wait')
                        : $t('dashboard.block3_approved')
                    }}</span>
                  </div>
                </template>
                <template v-slot:[`item.created_at`]="{ item }">
                  <span>{{ formatDate(item.created_at) }}</span>
                </template>
                <template v-slot:[`item.bank`]="{ item }">
                  <div class="w-100 ma-auto pt-1" style="width:25px;aspect-ratio:1;">
                    <div v-if="!item.bankCountry" class="d-flex justify-center align-center">
                      <img v-if="item.bank" alt="bank_img"
                        style="object-fit: contain;height: inherit;width: 100%;"
                        :src="require(`@/assets/images/logos/banks/${item.bank}.png`)" />
                    </div>
                    <div v-else class="d-flex justify-center align-center">
                      <img v-if="item.bank" alt="bank_img"
                        style="object-fit: contain;height: inherit;width: 100%;"
                        :src="`https://www.ak47.services/akasset-v2/banks/${item.bankCountry}/${item.bank}.webp`" />
                    </div>
                  </div>
                  <span>{{ item.bank_account }}</span>
                </template>
                <template v-slot:[`item.amount`]="{ item }">
                  <span>{{ item.amount | currency }}</span>
                </template>
              </v-data-table>
            </v-card-text>
          </div>
        </div>
      </v-card>
    </v-col>

    <v-col cols="12" md="4">
      <v-card class="card" style="height: 100%; border-radius: 1rem">
        <v-card-title> {{ $t('dashboard.block4_head') }}</v-card-title>
        <v-col cols="12" class="d-flex flex-column justify-center pa-4">
          <v-col cols="12" class="px-0 py-1">
            <v-row class="d-flex align-center ma-0">
              <v-col cols="7" class="pa-0">
                <v-avatar color="#28c76f20" style="border-radius: 0.75rem">
                  <v-icon size="24px" color="#28c76f"> mdi-trending-up </v-icon>
                </v-avatar>
                <span class="px-4 font-weight-medium">{{ $t('dashboard.block4_deposit') }}</span>
              </v-col>
              <v-col cols="5" class="pa-0 d-flex align-end">
                <span class="font-weight-medium" style="width: 100%; text-align: right; color: #0d9; line-height: 1.1"
                  >+{{ totalAllTimeDeposit | currency }}฿</span
                >
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="px-0 py-1">
            <v-row class="d-flex align-center ma-0">
              <v-col cols="7" class="pa-0">
                <v-avatar color="#f74b6c20" style="border-radius: 0.75rem">
                  <v-icon size="24px" color="#f74b6c"> mdi-trending-down </v-icon>
                </v-avatar>
                <span class="px-4 font-weight-medium">{{ $t('dashboard.block4_withdraw') }}</span>
              </v-col>
              <v-col cols="5" class="pa-0 d-flex align-end">
                <span
                  class="font-weight-medium"
                  style="width: 100%; text-align: right; color: #f74b6c; line-height: 1.1"
                  >-{{ totalAllTimeWithdraw | currency }}฿</span
                >
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="px-0 py-1">
            <v-row class="d-flex align-center ma-0">
              <v-col cols="7" class="d-flex flex-row pa-0">
                <v-avatar color="#00cfe820" style="border-radius: 0.75rem">
                  <v-icon size="24px" color="#00cfe8"> mdi-currency-usd </v-icon>
                </v-avatar>
                <div class="d-flex flex-column">
                  <span class="px-4 font-weight-medium" style="line-height: 1.2">{{
                    $t('dashboard.block4_bonus')
                  }}</span>
                  <span class="px-4 font-weight-regular" style="font-size: 11px; line-height: 1.2">{{
                    $t('dashboard.block4_bonus_detail')
                  }}</span>
                </div>
              </v-col>
              <v-col cols="5" class="pa-0 d-flex align-end">
                <span
                  class="font-weight-medium"
                  style="width: 100%; text-align: right; color: #00cfe8; line-height: 1.1"
                  >{{ totalAllBonus | currency }}฿</span
                >
              </v-col>
            </v-row>


          </v-col>
          <v-col cols="12" class="px-0 py-1">
            <v-row class="d-flex align-center ma-0">
              <v-col cols="7" class="d-flex flex-row pa-0">
                <v-avatar color="#ff9f4320" style="border-radius: 0.75rem">
                  <v-icon size="24px" color="#ff9f43"> mdi-wallet-outline </v-icon>
                </v-avatar>
                <div class="d-flex flex-column">
                  <span class="px-4 font-weight-medium" style="line-height: 1.2">{{
                    $t('dashboard.block4_game')
                  }}</span>
                  <span class="px-4 font-weight-regular" style="font-size: 11px; line-height: 1.2">{{
                    $t('dashboard.block4_game_detail')
                  }}</span>
                </div>
              </v-col>
              <v-col cols="5" class="pa-0 d-flex align-end">
                <span
                  class="font-weight-medium"
                  style="width: 100%; text-align: right; color: #ff9f43; line-height: 1.1"
                  >{{ totalAllGames | currency }}฿</span
                >
              </v-col>
            </v-row>
          </v-col>
        </v-col>
      </v-card>
    </v-col>

    <v-col cols="12" md="8">
      <v-card class="card" style="height: auto; border-radius: 1rem">
        <div class="d-flex flex-sm-row flex-column">
          <div class="flex-grow-1">
            <v-card-title>
              <span class="me-3 error--text">{{ $t('dashboard.block5_head') }}</span>
              <v-spacer></v-spacer>
            </v-card-title>
            <v-divider />
            <v-card-text class="pa-0 pb-8">
              <v-data-table
                :headers="headersDeposit"
                mobile-breakpoint="0"
                :items="depositTransaction"
                :no-data-text="$t('not_found')"
                :no-results-text="$t('not_found')"
                disable-pagination
                hide-default-footer
              >
                <!-- <template v-slot:[`item.status`]="{ item }">
                :class="`${
                  item.status === 'denied' ? 'error' : item.status === 'wait_member' ? 'warning' : 'success'
                } popout`"
              ></div>
              <span>{{
                item.status === 'denied' ? 'ไม่อนุมัติ' : item.status === 'wait_member' ? 'รออนุมัติ' : 'อนุมัติ'
              }}</span>
            </template> -->
                <!-- <template v-slot:[`item.bank`]="{ item }">
              <span>{{ formatDate(item.created_at) }}</span>
            </template> -->
                <template v-slot:[`item.created_at`]="{ item }">
                  <span>{{ formatDate(item.created_at) }}</span>
                </template>
                <template v-slot:[`item.bank`]="{ item }">
                  <div class="w-100 ma-auto pt-1" style="width:25px;aspect-ratio:1;">
                    <div v-if="!item.bankCountry" class="d-flex justify-center align-center">
                      <img v-if="item.bank" alt="bank_img"
                        style="object-fit: contain;height: inherit;width: 100%;"
                        :src="require(`@/assets/images/logos/banks/${item.bank}.png`)" />
                    </div>
                    <div v-else class="d-flex justify-center align-center">
                      <img v-if="item.bank" alt="bank_img"
                        style="object-fit: contain;height: inherit;width: 100%;"
                        :src="`https://www.ak47.services/akasset-v2/banks/${item.bankCountry}/${item.bank}.webp`" />
                    </div>
                  </div>
                  <span>{{ item.bank_account }}</span>
                </template>
                <template v-slot:[`item.amount`]="{ item }">
                  <span>{{ item.amount | currency }}</span>
                </template>
              </v-data-table>
            </v-card-text>
          </div>
        </div>
      </v-card>
    </v-col>
    <v-col cols="12" md="4">
      <v-card class="card" style="height: auto; border-radius: 1rem">
        <v-card-title style="letter-spacing: 0"> {{ $t('dashboard.block6_head') }} </v-card-title>

        <div v-if="!this.isLoadingChartAffiliateTotalPlay">
          <!-- <apexchart type="donut" :options="chartAffiliateTotalPlayOptions" :series="chartAffiliateTotalPlaySeries">
          </apexchart> -->

          <apexchart type="bar" :options="chartAffiliateTotalPlayOptions" :series="chartAffiliateTotalPlaySeries">
          </apexchart>
        </div>

        <v-card-text class="d-flex pa-0">
          <v-card-text class="pt-4 text-center">
            <span>{{ $t('dashboard.block6_total_played') }}</span> <br />
            <span class="font-weight-bold text-h6">{{ affiliateTotalReport.total_play_all | currency }} ฿</span>
          </v-card-text>
        </v-card-text>
        <v-divider />
        <v-card-text class="d-flex pa-0">
          <v-card-text class="col-4 d-flex flex-column align-center pt-4 text-center">
            <span>{{ $t('dashboard.block6_sport') }}</span>
            <span class="font-weight-bold">{{ affiliateTotalReport.total_play_sport | currency }} ฿</span>
          </v-card-text>
          <v-divider vertical />
          <v-card-text class="col-4 d-flex flex-column align-center text-center">
            <span>{{ $t('dashboard.block6_slot') }}</span>
            <span class="font-weight-bold">{{ affiliateTotalReport.total_play_slot | currency }} ฿</span>
          </v-card-text>
          <v-divider vertical />
          <v-card-text class="col-4 d-flex flex-column align-center text-center">
            <span>{{ $t('dashboard.block6_casino') }}</span>
            <span class="font-weight-bold">{{ affiliateTotalReport.total_play_casino | currency }} ฿</span>
          </v-card-text>
        </v-card-text>
      </v-card>
    </v-col>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { mdiDotsVertical, mdiTrendingUp, mdiCurrencyUsd } from '@mdi/js'
import moment from 'moment-timezone'

export default {
  props: {
    currentDate: { type: String, require: false },
    members: {
      all_member: 0,
      member_deposit: 0,
      member_not_deposit: 0,
    },
    report: {
      type: Object,
      require: false,
    },
    accountBanks: {
      type: Array,
      default: () => [],
    },
    depositTransaction: {
      type: Array,
      default: () => [],
    },
    withdrawTransaction: {
      type: Array,
      default: () => [],
    },
    dailyReports: {
      type: Array,
      default: () => [],
    },
    monthlyReports: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    VueApexCharts,
    apexchart: VueApexCharts,
  },
  setup() {
    const chartOptionsDeposit = {
      chart: {
        type: 'area',
        toolbar: {
          show: false,
        },
        foreColor: '#fff',
        sparkline: {
          enabled: true,
        },
        dropShadow: {
          enabled: true,
          top: 10,
          left: 0,
          blur: 3,
          color: '#000',
          opacity: 0.35,
        },
        offsetY: 10,
      },
      colors: ['#00dd99'],
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'light',
          gradientToColors: ['#ffffff'],
          shadeIntensity: 1,
          type: 'vertical',
          opacityFrom: 0.6,
          opacityTo: 0.5,
          stops: [0, 75],
        },
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
      stroke: {
        width: [3],
        curve: 'smooth',
      },
    }

    const chartOptionsWithdraw = {
      chart: {
        type: 'area',
        toolbar: {
          show: false,
        },
        foreColor: '#fff',
        sparkline: {
          enabled: true,
        },
        dropShadow: {
          enabled: true,
          top: 10,
          left: 0,
          blur: 3,
          color: '#000',
          opacity: 0.35,
        },
        offsetY: 10,
      },
      colors: ['#f74b6c'],
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'light',
          gradientToColors: ['#ffffff'],
          shadeIntensity: 1,
          type: 'vertical',
          opacityFrom: 0.6,
          opacityTo: 0.5,
          stops: [0, 75],
        },
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
      stroke: {
        width: [3],
        curve: 'smooth',
      },
    }

    const chartOptionsBonus = {
      chart: {
        type: 'area',
        toolbar: {
          show: false,
        },
        foreColor: '#fff',
        sparkline: {
          enabled: true,
        },
        dropShadow: {
          enabled: true,
          top: 10,
          left: 0,
          blur: 3,
          color: '#000',
          opacity: 0.35,
        },
        offsetY: 10,
      },
      colors: ['#00cfe8'],
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'light',
          gradientToColors: ['#ffffff'],
          shadeIntensity: 1,
          type: 'vertical',
          opacityFrom: 0.6,
          opacityTo: 0.5,
          stops: [0, 75],
        },
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
      stroke: {
        width: [3],
        curve: 'smooth',
      },
    }

    const chartOptionsGame = {
      chart: {
        type: 'area',
        toolbar: {
          show: false,
        },
        foreColor: '#fff',
        sparkline: {
          enabled: true,
        },
        dropShadow: {
          enabled: true,
          top: 10,
          left: 0,
          blur: 3,
          color: '#000',
          opacity: 0.35,
        },
        offsetY: 10,
      },
      colors: ['#ff9f43'],
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'light',
          gradientToColors: ['#ffffff'],
          shadeIntensity: 1,
          type: 'vertical',
          opacityFrom: 0.6,
          opacityTo: 0.5,
          stops: [0, 75],
        },
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
      stroke: {
        width: [3],
        curve: 'smooth',
      },
    }

    return {
      chartOptionsDeposit,
      chartOptionsWithdraw,
      chartOptionsBonus,
      chartOptionsGame,
      icons: {
        mdiDotsVertical,
        mdiTrendingUp,
        mdiCurrencyUsd,
      },
    }
  },
  data() {
    return {
      headersAccountBanks: [
        {
          text: this.$t('dashboard.block1_head1'),
          value: 'bank',
          align: 'center',
          sortable: false,
        },
        // {
        //   text: 'ชื่อบัญชี',
        //   value: 'bank_name',
        //   sortable: false,
        // },
        {
          text: this.$t('dashboard.type'),
          value: 'type',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('dashboard.balance'),
          value: 'balance',
          align: 'end',
          sortable: false,
        },
      ],
      headersDeposit: [
        {
          text: this.$t('dashboard.block5_name'),
          value: 'bank_name',
          width: '160px',
          sortable: false,
        },
        {
          text: this.$t('dashboard.block5_bank'),
          value: 'bank',
          align: 'center',
          sortable: false,
        },
        // {
        //   text: 'เลขที่บัญชี',
        //   value: 'bank_account',
        //   sortable: false,
        // },
        {
          text: this.$t('dashboard.block5_createat'),
          value: 'created_at',
          sortable: false,
        },
        {
          text: this.$t('dashboard.block5_amount'),
          value: 'amount',
          align: 'end',
          sortable: false,
        },
      ],
      headersWithdraw: [
        {
          text: this.$t('dashboard.block3_name'),
          value: 'bank_name',
          width: '160px',
          sortable: false,
        },
        {
          text: this.$t('dashboard.block3_status'),
          value: 'status',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('dashboard.block3_bank'),
          value: 'bank',
          align: 'center',
          sortable: false,
        },
        // {
        //   text: 'เลขที่บัญชี',
        //   value: 'bank_account',
        //   sortable: false,
        // },
        {
          text: this.$t('dashboard.block3_createat'),
          value: 'created_at',
          width: '95px',
          sortable: false,
        },
        {
          text: this.$t('dashboard.block3_amount'),
          value: 'amount',
          align: 'end',
          sortable: false,
        },
      ],

      chartDepositSeries: [
        {
          data: [0, 0],
        },
      ],
      chartWithdrawSeries: [
        {
          data: [0, 0],
        },
      ],
      chartBonusSeries: [
        {
          data: [0, 0],
        },
      ],
      chartGameSeries: [
        {
          data: [0, 0],
        },
      ],
      chartDepositWithdrawSummarySeries: [
        {
          name: this.$t('dashboard.block2_deposit'),
          data: [],
        },
        {
          name: this.$t('dashboard.block2_withdraw'),
          data: [],
        },
        {
          name: this.$t('dashboard.block2_net_profit'),
          data: [],
        },
      ],
      chartDepositWithdrawSummaryOptions: {
        chart: {
          height: 350,
          type: 'area',
          toolbar: {
            offsetX: 0,
            offsetY: 350,
            tools: {
              download: false,
            },
            horizontalAlign: 'left',
          },
        },
        colors: ['#00dd99', '#f74b6c', '#00cfe8'],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
        },
        xaxis: {
          type: 'datetime',
          categories: [],
        },
        tooltip: {
          x: {
            format: 'dd-MM-yyyy',
          },
          y: {
            formatter: function (val) {
              const currencySymbol = '฿'
              return `${val ? val.toLocaleString('en-US') : '0.00'}${currencySymbol}`
            },
          },
        },
        legend: {
          position: 'top',
          offsetY: 0,
          offsetX: -20,
          fontFamily: 'Kanit',
          horizontalAlign: 'left',
        },
      },

      chartDepositWithdrawSummarySeriesMonth: [
        {
          name: this.$t('dashboard.block2_deposit'),
          data: [],
        },
        {
          name: this.$t('dashboard.block2_withdraw'),
          data: [],
        },
        {
          name: this.$t('dashboard.block2_net_profit'),
          data: [],
        },
      ],
      chartDepositWithdrawSummaryOptionsMonth: {
        chart: {
          height: 350,
          type: 'area',
          toolbar: {
            offsetX: 0,
            offsetY: 350,
            tools: {
              download: false,
            },
            horizontalAlign: 'left',
          },
        },
        colors: ['#00dd99', '#f74b6c', '#00cfe8'],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
        },
        xaxis: {
          type: 'datetime',
          categories: [],
        },
        tooltip: {
          x: {
            format: 'MM-yyyy',
          },
        },
        legend: {
          position: 'top',
          offsetY: 0,
          offsetX: -20,
          fontFamily: 'Kanit',
          horizontalAlign: 'left',
        },
      },

      chartAffiliateTotalPlaySeries: [],
      chartAffiliateTotalPlayOptions: {
        xaxis: {
          categories: [this.$t('sport'), this.$t('slot'), this.$t('casino')]
        },
        plotOptions: {
          bar: {
            columnWidth: '50%',
            distributed: true,
          }
        },
        // dataLabels: {
        //   enabled: false,
        // },
        tooltip: {
          y: {
            formatter: function (val) {
              const currencySymbol = '฿'
              return `${val ? val.toLocaleString('en-US') : '0.00'}${currencySymbol}`
            },
          },
        },
        legend: {
          show: false,
        },
      },

      totalProfit: 0,
      totalDeposit: 0,
      totalWithdraw: 0,
      totalBonus: 0,
      totalGames: 0,
      totalAllBonus: 0,
      totalAllGames: 0,
      totalAllTimeProfit: 0,
      totalAllTimeDeposit: 0,
      totalAllTimeWithdraw: 0,
      selectedMonth: 'All',
      selectedChartType: 'day',
      monthOptions: [
        { text: this.$t('dashboard.all'), textEN: 'All', value: 'All' },
        { text: this.$t('dashboard.month1'), textEN: 'January', value: 'January' },
        { text: this.$t('dashboard.month2'), textEN: 'February', value: 'February' },
        { text: this.$t('dashboard.month3'), textEN: 'March', value: 'March' },
        { text: this.$t('dashboard.month4'), textEN: 'April', value: 'April' },
        { text: this.$t('dashboard.month5'), textEN: 'May', value: 'May' },
        { text: this.$t('dashboard.month6'), textEN: 'June', value: 'June' },
        { text: this.$t('dashboard.month7'), textEN: 'July', value: 'July' },
        { text: this.$t('dashboard.month8'), textEN: 'August', value: 'August' },
        { text: this.$t('dashboard.month9'), textEN: 'September', value: 'September' },
        { text: this.$t('dashboard.month10'), textEN: 'October', value: 'October' },
        { text: this.$t('dashboard.month11'), textEN: 'November', value: 'November' },
        { text: this.$t('dashboard.month12'), textEN: 'December', value: 'December' },
      ],
      totalDepositWithdraw: [],
      affiliateTotalReport: { total_play_all: 0, total_play_sport: 0, total_play_slot: 0, total_play_casino: 0 },
      isLoadingChartDepositWithdrawSummary: true,
      isLoadingChartDepositWithdrawSummaryMonth: true,
      isLoadingChartAffiliateTotalPlay: true,
    }
  },
  async created() {
    this.setChartDepositWithdrawSummarySeries()
    this.setChartAffiliateTotalPlaySeries()
    this.setProfitSummary()
    this.setcurrentMonth()

    this.refreshdata()
  },
  methods: {
    async setChartDepositWithdrawSummarySeries() {
      const dateArray = []
      const depositArray = []
      const withdrawArray = []
      const profitArray = []
      const depositSeriesArr = []
      const withdrawSeriesArr = []
      const bonusSeriesArr = []
      const gamesSeriesArr = []

      const firstDateOfMonth = moment().startOf('month')
      const totalDaysInMonth = firstDateOfMonth.daysInMonth()

      let resDaily = await this.$store.dispatch('dashboardDaily')
      let dailyData = resDaily.reverse()
      for (let i = 0; i < totalDaysInMonth; i++) {
        if (dailyData && dailyData[i]) {
          depositSeriesArr.push(parseFloat(dailyData[i].deposit))
          withdrawSeriesArr.push(parseFloat(dailyData[i].withdraw))
          bonusSeriesArr.push(parseFloat(dailyData[i].bonus))
          gamesSeriesArr.push(parseFloat(dailyData[i].game))

          depositArray.push(parseFloat(dailyData[i].deposit))
          withdrawArray.push(parseFloat(dailyData[i].withdraw))
          profitArray.push(parseFloat(dailyData[i].profit))
        } else {
          depositArray.push(0)
          withdrawArray.push(0)
          profitArray.push(0)
          bonusSeriesArr.push(0)
          gamesSeriesArr.push(0)
        }

        const previousDate = firstDateOfMonth.clone().add(i, 'days')
        const jsDate = previousDate.toDate()
        jsDate.setUTCHours(jsDate.getUTCHours() + 7)
        const formattedDate = jsDate.toISOString()
        dateArray.push(formattedDate)
      }

      this.chartDepositSeries[0].data = depositSeriesArr
      this.chartWithdrawSeries[0].data = withdrawSeriesArr
      this.chartBonusSeries[0].data = bonusSeriesArr
      this.chartGameSeries[0].data = gamesSeriesArr

      this.chartDepositWithdrawSummarySeries[0].data = depositArray
      this.chartDepositWithdrawSummarySeries[1].data = withdrawArray
      this.chartDepositWithdrawSummarySeries[2].data = profitArray
      this.chartDepositWithdrawSummaryOptions.xaxis.categories = dateArray

      this.isLoadingChartDepositWithdrawSummary = false
    },
    async setChartAffiliateTotalPlaySeries() {
      let resAffTotalPlay = await this.$store.dispatch('dashboardAffiliateTotalPlay')
      if (resAffTotalPlay) {
        let resultData = {
          total_play_all: 0,
          total_play_sport: 0,
          total_play_slot: 0,
          total_play_casino: 0,
        }
        const totalData = resAffTotalPlay?.data
        resultData.total_play_all = resAffTotalPlay.all_total
        for (let dt of totalData) {
          if (dt.type_game === 'CASINO') {
            resultData.total_play_casino = dt.total_play
          } else if (dt.type_game === 'SLOT') {
            resultData.total_play_slot = dt.total_play
          } else if (dt.type_game === 'SPORT') {
            resultData.total_play_sport = dt.total_play
          }
        }
        const formatSport = resultData.total_play_sport
        const formatSlot = resultData.total_play_slot
        const formatCasino = resultData.total_play_casino
        this.chartAffiliateTotalPlaySeries = [{ name: '', data: [ formatSport, formatSlot, formatCasino ] }]
        this.affiliateTotalReport = resultData
      }

      this.isLoadingChartAffiliateTotalPlay = false
    },
    setProfitSummary() {
      // this.$store.dispatch('dashboardMonthly').then(result => {
      //   if (result) {
      //     const setData = result[0]
      //     this.totalProfit = setData.profit
      //     this.totalDeposit = setData.deposit
      //     this.totalWithdraw = setData.withdraw
      //   }
      // })

      this.$store.dispatch('dashboardMonthlyV2').then(result => {
        if (result) {
          const { data, total, bonus_data, game_data } = result[0]
          this.totalDepositWithdraw = data
          this.totalAllTimeDepositWithdraw = total

          this.totalAllTimeProfit = this.totalAllTimeDepositWithdraw.profit
          this.totalAllTimeDeposit = this.totalAllTimeDepositWithdraw.deposit
          this.totalAllTimeWithdraw = this.totalAllTimeDepositWithdraw.withdraw

          this.totalAllBonus = total.bonus
          this.totalAllGames = total.game

          this.totalCashback = bonus_data.cashback
          this.totalCoupon = bonus_data.coupon
          this.totalPromotion = bonus_data.promotion
          this.totalReferral = bonus_data.referral

          this.totalDailyLogin = game_data.dailyLogin
          this.totalLotto = game_data.lotto
          this.totalLuckyCard = game_data.luckyCard
          this.totalPredictFootball = game_data.predictFootball
          this.totalWheel = game_data.wheel

          this.selectedMonthChart()

          const monthArray = []
          const depositSeriesArr = []
          const withdrawSeriesArr = []
          const protfitSeriesArr = []
          for (let i = 0; i < this.totalDepositWithdraw.length; i++) {
            depositSeriesArr.push(parseFloat(this.totalDepositWithdraw[i].deposit))
            withdrawSeriesArr.push(parseFloat(this.totalDepositWithdraw[i].withdraw))
            protfitSeriesArr.push(parseFloat(this.totalDepositWithdraw[i].profit))

            const month = this.totalDepositWithdraw[i].month
            const currentMonth = moment(month, 'YYYY-MM')
            const firstDayOfMonth = currentMonth.date(1)
            const formattedDate = firstDayOfMonth.format('YYYY-MM-DD')
            monthArray.push(formattedDate)
          }
          this.chartDepositWithdrawSummarySeriesMonth[0].data = depositSeriesArr
          this.chartDepositWithdrawSummarySeriesMonth[1].data = withdrawSeriesArr
          this.chartDepositWithdrawSummarySeriesMonth[2].data = protfitSeriesArr
          this.chartDepositWithdrawSummaryOptionsMonth.xaxis.categories = monthArray

          this.isLoadingChartDepositWithdrawSummaryMonth = false
        }
      })
    },
    setcurrentMonth() {
      if(this.currentDate) {
        const currentDate = moment(this.currentDate, 'DD-MM-YYYY HH:mm:ss')
        const formatMonth = currentDate.format('MMMM')
        const getMonth = this.monthOptions.filter(item => formatMonth === item.value)
        if (getMonth) {
          const month = getMonth[0]
          this.selectedMonth = month.value
        }
      }
    },
    selectedMonthChart() {
      let setData = null
      if (this.selectedMonth === 'All') {
        setData = this.totalAllTimeDepositWithdraw
      } else {
        const monthNum = moment(this.selectedMonth, 'MMMM').month()
        setData = this.totalDepositWithdraw[monthNum]
      }

      this.totalProfit = setData.profit
      this.totalDeposit = setData.deposit
      this.totalWithdraw = setData.withdraw
      this.totalBonus = setData.bonus
      this.totalGames = setData.game
    },
    refreshdata() {
      setInterval(() => {
        this.setChartDepositWithdrawSummarySeries()
        this.setChartAffiliateTotalPlaySeries()
        this.setProfitSummary()
      }, 60000)
    },
    formatDate(date) {
      return moment(date).format('YY-MM-DD HH:mm')
    },
  },
}
</script>
<style scoped>
.popout.active {
  box-shadow: 0px 0px 8px 2px #ffb40044;
  animation: popout 0.75s cubic-bezier(0.755, 0.05, 0.855, 0.06) infinite alternate;
  -webkit-animation: popout 0.75s cubic-bezier(0.755, 0.05, 0.855, 0.06) infinite alternate;
}

.popout {
  border-radius: 50%;
  width: 15px;
  height: 15px;
  aspect-ratio: 1/1;
}

.flex-grow-1 {
  width: 100% !important;
}

@keyframes popout {
  from {
    transform: scale(0.5);
  }

  75% {
    transform: scale(1.25);
  }

  to {
    transform: scale(1);
  }
}

@-webkit-keyframes popout {
  from {
    -webkit-transform: scale(0.5);
  }

  80% {
    -webkit-transform: scale(1.25);
  }

  to {
    -webkit-transform: scale(1);
  }
}

.v-application .success {
  background-color: #0d9 !important;
  border-color: #0d9 !important;
}

.dw-summary-container {
  height: auto;
}

.dw-summary-text-container {
  padding-bottom: 3rem !important;
}

@media (min-width: 960px) {
  .dw-summary-container {
    height: 100%;
  }

  .dw-summary-text-container {
    padding-bottom: 0;
  }
}
</style>
