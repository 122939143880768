<template>
  <v-card>
    <v-card-title>
      ผลประกอบการวันนี้
    </v-card-title>
    <v-divider/>
    <v-card-text class="d-flex pa-0">
      <v-card-text class="pt-4 text-center">
        <span>ยอดฝากวันนี้</span> <br>
        <span class="font-weight-bold">{{ report.total_amount_deposit | currency }} ฿</span>
      </v-card-text>
      <v-divider vertical/>
      <v-card-text class="text-center">
        <span>ยอดถอนวันนี้</span><br>
        <span class="font-weight-bold">{{ report.total_amount_withdraw | currency }} ฿</span>
      </v-card-text>
      <v-divider vertical/>
      <v-card-text class="text-center">
        <span>กำไรสุทธิวันนี้</span> <br>
        <span class="font-weight-bold">{{ report.total_amount_profit | currency }} ฿</span>
      </v-card-text>
    </v-card-text>
    
    <v-divider/>
    <v-card-text class="d-flex pa-0">
      <v-card-text class="pt-4 text-center">
        <span>สมาชิกวันนี้</span> <br>
        <span class="font-weight-bold">{{ report.total_member_register }}</span>
      </v-card-text>
      <v-card-text class="pt-4 px-3 text-center">
        <span>รายการฝากวันนี้</span><br>
        <span class="font-weight-bold">{{ report.count_deposit }}</span>
      </v-card-text>
      <v-card-text class="pt-4 px-3 text-center">
        <span>รายการถอนวันนี้</span> <br>
        <span class="font-weight-bold">{{ report.count_withdraw }}</span>
      </v-card-text>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    report: {
      type: Object,
      require: true
    }
  }
}
</script>