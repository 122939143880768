<template>
  <v-card>
    <v-card-title>
      ยอดเล่นทั้งหมดของยูสภายใต้พันธมิตร
    </v-card-title>
     <v-card-text class="d-flex pa-0">
      <v-card-text class="pt-4 text-center">
        <span>ยอดเล่นทั้งหมด</span> <br>
        <span class="font-weight-bold text-h6">{{ report.total_play_all | currency  }} ฿</span>
      </v-card-text>
    </v-card-text>
    <v-divider/>
    <v-card-text class="d-flex pa-0">
      <v-card-text class="pt-4 text-center">
        <span>กีฬา</span> <br>
        <span class="font-weight-bold">{{ report.total_play_sport | currency }} ฿</span>
      </v-card-text>
      <v-divider vertical/>
      <v-card-text class="text-center">
        <span>สล็อต</span><br>
        <span class="font-weight-bold">{{ report.total_play_slot | currency }} ฿</span>
      </v-card-text>
      <v-divider vertical/>
      <v-card-text class="text-center">
        <span>คาสิโน</span> <br>
        <span class="font-weight-bold">{{ report.total_play_casino | currency }} ฿</span>
      </v-card-text>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    report: {
      type: Object,
      require: true
    }
  }
}
</script>
